@font-face {
    font-family: "Roboto";
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}
body {
    font-family: "Roboto";
    background-color: #1c2c3f;
    color: #ffffff;
    height: 100%;
    margin: 0;
    padding: 0;
}
.primary {
  color: var(--primary-color);
}
.accent {
  color: var(--accent-color);
}
.warn {
  color: var(--warn-color, #e5005b);
}
div.logo {
    margin-top: 50px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    font-size: 42px;
    text-align: center;
}
div.logo img {
    width: 60%;
}

.page-block {
  display: block;
  padding: 40px 15px 80px 15px;

  @media (max-width: 575px) {
    &.container {
      max-width: inherit;
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@media (max-width: 576px) {
    div.logo {
        width: 95%;
    }
    div.logo img {
        width: 100%;
    }
}
div.cookie-banner {
    position: fixed;
    padding: 15px;
    left: 0;
    bottom: 0;
    background-color: rgba(70, 81, 99, 0.9);
    color: #fff;
    width: 100%;
    z-index: 99999;
    font-size: 11px;
}
div.cookie-banner a {
    font-size: 12px;
    background-color: #a4af12;
}
div.margin-top {
    margin-top: 100px;
}
h1.index {
    margin-top: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.3) 25%,
        rgba(255, 255, 255, 0.3) 75%,
        rgba(255, 255, 255, 0) 100%
    );
}
@media (max-width: 576px) {
    h1.index {
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    div.margin-top {
        margin-top: 0;
    }
}
h2 {
    font-size: 26px;
}
.btn-outline-secondary {
    color: #1c2c3f;
    border-color: #b7ce11;
    background-color: #b7ce11;
}
.btn-outline-secondary:hover {
    color: #1c2c3f;
    border-color: #b7ce11;
    background-color: #c7d86f;
}
button.btn {
    margin-top: 2rem;
}
.is-invalid {
    color: #e5005b;
}
.invalid-feedback {
    display: block;
    font-size: 14px;
}
.mb-3 {
    margin-bottom: 0 !important;
}
#map-canvas {
    position: absolute;
    top: 0;
    bottom: -200px;
    left: 0;
    right: 0;
    z-index: 0;
}
.content-container {
    z-index: 100;
    position: relative;
}
.btn.btn-default.btn-large {
    background-color: #b7ce11;
    border-radius: 40px;
    font-size: 24px;
}
.footer {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 18px;
}
@media (max-width: 769px) {
    .margin-top-small {
        margin-top: 50px;
    }
}
.form-control,
.form-control:focus,
select.form-control {
    border: 0;
    border-bottom: 1px solid #b7ce11;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
.form-group > label {
    display: none;
}
.form-check {
    margin-top: 15px;
    padding-left: 0.75rem;
}
.form-check input[type="checkbox"] {
    opacity: 0;
}

.form-check label {
    position: relative;
    display: inline-block;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 22px;
}

.form-check label::before,
.form-check label::after {
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.form-check label::before {
    height: 16px;
    width: 16px;

    border: 1px solid #b7ce11;
    left: 0px;

    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    top: 3px;
}

/*Checkmark of the fake checkbox*/
.form-check label::after {
    height: 5px;
    width: 9px;
    border-left: 2px solid;
    border-bottom: 2px solid;

    transform: rotate(-45deg);

    left: 4px;
    top: 7px;
}

/*Hide the check mark by default*/
.form-check input[type="checkbox"] + label::after {
    content: none;
}

/* Unhide on the checked state*/
.form-check input[type="checkbox"]:checked + label::after {
    content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.form-check input[type="checkbox"]:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}
.form-control.is-valid {
    border-color: #b7ce11;
}
.form-check-input.is-valid ~ .form-check-label {
    color: #ffffff;
}
p.welcome {
    font-size: 2rem;
}

.btn-spacing {
    margin-right: 2px;
    margin-left: 2px;
}
