@import "https://api.mapbox.com/mapbox-gl-js/v2.1.1/mapbox-gl.css";
@import "projects/shared/style";
@import "projects/shared/styles/theme/orange";
@import "projects/shared/styles/headers";
@import "projects/shared/styles/text-colors";
@import "projects/shared/styles/buttons";
@import "projects/shared/styles/fonts";
@import "projects/shared/styles/dialog-modal";

body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  padding: 0;

  --pw-red: #{$pink-5b};
  --pw-green: #{$green-99FF00};
  --pw-bondi-blue: #{$bondi-blue-ae};
  --pw-orange: #{$orange-19};
  --pw-greeenyellow: #{$green-11};
  --pw-black: black;
}

h1, h2, h3 {
  color: var(--primary-color);
  text-transform: none;

  &:first-child {
    margin-top: 0;
  }
}

.page-block {
  padding: 36px 15px;
}
